import React, { FC } from "react";

export type Languages = "en" | "nl";

export const languageContext = React.createContext<Languages>("en");

export const LanguageContextProvider: FC = ({ children }) => {
  let hostname = "";
  if (typeof window !== "undefined") {
    hostname = window.location.hostname;
  }
  let language: Languages;
  if (hostname.includes("localhost")) {
    language = "en";
  } else {
    language = hostname === "kluscv.nl" ? "nl" : "en";
  }
  return (
    <languageContext.Provider value={language}>
      {children}
    </languageContext.Provider>
  );
};
