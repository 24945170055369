import { extendTheme } from "@chakra-ui/react";
import "@fontsource/poppins/700.css"; // not the right place according to the guide but it works in combination with gatsby
import "@fontsource/poppins/400.css";

const theme = {
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  colors: {
    turqoise: {
      50: "#daffff",
      100: "#adfffa",
      200: "#7dfef6",
      300: "#4efef2",
      400: "#27feee",
      500: "#01A297",
      600: "#01b1a5",
      700: "#007f76",
      800: "#004d47",
      900: "#001b1a",
    },
    lila: "#C4B8FF",
    lilalight: "#e9e4ff",
    liladark: "#5E5D7E",
    lime: "#D7F85B",
    limelight: "#f8ffde",
    peach: "#FF7156",
    darkblue: "#191847",
  },
  styles: {
    global: {
      "html, body": {
        color: "darkblue",
      },
      a: {
        color: "turqoise",
      },
    },
  },
  components: {
    Link: {
      baseStyle: {
        color: "turqoise.500",
      },
    },
    Button: {
      variants: {
        ghost: {
          color: "turqoise.500",
        },
      },
    },
  },
};

export default extendTheme(theme);
